'use client'

import styled from 'styled-components'

import {
  Box,
  ContentText,
  IconStarFilled,
  fontSizes,
  fontWeights,
  px2rem,
  spacing,
} from '@fortum/elemental-ui'

import type { ReviewEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import * as styleMixins from '@/shared/style/styleMixins'

import { Icon } from '../Icon'

export type ReviewProps = ReviewEntry & { isLightOnDark?: boolean }

/**
 * Component presenting customer quote with marking in number of highlighted rating
 *
 * TODO: would be nice to make it as a client component (not urgent though)
 */
export const Review = ({
  content,
  author,
  authorDescription,
  rating,
  isLightOnDark,
}: ReviewProps) => {
  const theme = useTheme()
  const colors = isLightOnDark
    ? {
        icon: theme.colors.textLightOnDark,
        iconHighlighted: theme.colors.textLightOnDarkAccent,
        text: theme.colors.textLightOnDark,
        textMuted: theme.colors.textLightOnDark,
      }
    : {
        icon: theme.colors.textDisabled,
        iconHighlighted: theme.colors.textPositive,
        text: theme.colors.textPrimary,
        textMuted: theme.colors.textSecondary,
      }

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={{ default: spacing.xs, l: spacing.m }}
      gap={{ default: spacing.xs, l: spacing.m }}
      backgroundColor={isLightOnDark ? 'transparent' : theme.colors.backgroundPrimary}
      border={`${px2rem(1)} solid ${theme.colors.borderPrimary}`}
      borderRadius="0.5rem"
      width={{ default: px2rem(284), s: px2rem(328), l: px2rem(412) }}
      style={{ boxSizing: 'border-box' }}
    >
      <Box>
        {rating && (
          <Box pb={spacing.xxs}>
            {Array.from({ length: 5 }, (_, i) => i < rating).map((highlight, i) => (
              <Icon
                key={i}
                icon={IconStarFilled}
                color={highlight ? colors.iconHighlighted : colors.icon}
              />
            ))}
          </Box>
        )}
        {content && (
          <PrimaryText title={content} color={colors.text}>
            <q>{content}</q>
          </PrimaryText>
        )}
      </Box>
      <Box>
        {author && (
          <ContentText color={colors.text} fontSize={fontSizes.l} weight={fontWeights.medium}>
            {author}
          </ContentText>
        )}
        {authorDescription && (
          <ContentText color={colors.textMuted}>{authorDescription}</ContentText>
        )}
      </Box>
    </Box>
  )
}

const PrimaryText = styled(ContentText)`
  ${styleMixins.truncateMultiLineText({ default: 6, xl: 5 })}
`
