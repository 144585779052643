'use client'

import type { ComponentProps } from 'react'

// eslint-disable-next-line no-restricted-imports
import { ForwardLink as ElementalForwardLink } from '@fortum/elemental-ui'

import { Link } from '@/i18n/navigation'

export type ForwardLinkProps = ComponentProps<ElementalForwardLink>

/**
 * Wrapper around `ForwardLink` from Elemental that uses a Next.js link.
 *
 * Have to be a client component since it passes Link as a function which
 * is forbidden when you set an interraction between server and client components.
 */
export const ForwardLink = (props: ForwardLinkProps) => {
  return <ElementalForwardLink tag={Link} {...props} />
}
