import { useTranslations } from 'next-intl'

import { Box, ButtonPill, ContentText, IconContact, Modal, spacing } from '@fortum/elemental-ui'

import { Link } from '@/i18n/navigation'
import { Icon } from '@/shared/components/Icon'
import { StyledHeading } from '@/shared/components/StyledHeading'
import { useGlobalStore } from '@/shared/store/provider'
import { stepEvent } from '@/shared/utils/sendGTMEvent'

type HighConsumptionModalProps = {
  isOpened: boolean
  handleClose: VoidFunction
}

export const HighConsumptionModal = ({ isOpened, handleClose }: HighConsumptionModalProps) => {
  const t = useTranslations('quickSearchForm')
  const t1 = useTranslations('quickSearchForm.highConsumptionModal')

  const { usageOption, estimatedConsumption, setUsageOption } = useGlobalStore(
    (state) => state.housingInfo,
  )

  return (
    <Modal opened={isOpened} onClose={handleClose} hideCloseButton={true}>
      <Box display="flex" flexDirection="column" alignItems="center" gap={spacing.xxs}>
        <Icon icon={IconContact} size={48} />
        <StyledHeading level={4}>{t1('title')}</StyledHeading>
        <Box display="flex" flexDirection="column" alignItems="center" gap={spacing.xxl}>
          <ContentText size="m" textAlign="center">
            {t1('text')}
          </ContentText>
          <Box
            display="flex"
            flexDirection={{ default: 'column', s: 'row' }}
            gap={spacing.xs}
            justifyContent="center"
          >
            <ButtonPill
              tag={Link}
              onClick={(event) => {
                event.preventDefault()
                stepEvent('find_contract', {
                  business_size: usageOption,
                  est_consumption: estimatedConsumption,
                })
                setUsageOption(undefined)
                location.assign(t('contactFormLink'))
              }}
            >
              {t1('contactButton')}
            </ButtonPill>
            <ButtonPill onClick={handleClose} status="secondary">
              {t1('backButton')}
            </ButtonPill>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
