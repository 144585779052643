import { useTranslations } from 'next-intl'

import type { BoxProps } from '@fortum/elemental-ui'
import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import { getElementalTheme } from '@/style/theme'

export const QuizAdditionalInfoBox = ({ content, ...props }: { content: string } & BoxProps) => {
  const { colors } = getElementalTheme()
  const t = useTranslations('quiz')

  return (
    <Box
      paddingHorizontal={spacing.xs}
      paddingVertical={spacing.xxs}
      textColor={colors.textLightOnDark}
      backgroundColor={colors.textLightOnDarkSubtle}
      borderRadius={spacing.xxxxs}
      width={{ default: '100vw', m: 'auto' }}
      position={{ default: 'fixed', m: 'initial' }}
      bottom="0"
      left="0"
      {...props}
    >
      <ContentText size="l">{t('infoLabel')}</ContentText>
      <ContentText>{content}</ContentText>
    </Box>
  )
}
