'use client'

import type { ChangeEvent } from 'react'
import { useController } from 'react-hook-form'

import { Box, ContentText, IconCheck, Loader, spacing } from '@fortum/elemental-ui'

import { InputPostalCode } from '@/shared/components/InputPostalCode'
import { getPostalCodeRegex } from '@/shared/components/InputPostalCode/utils'
import { browserEnvs } from '@/shared/envs'
import { useTheme } from '@/shared/hooks/useTheme'

import { StyledInput } from '../styled'
import type { FormFiledValues, QuickSearchForm } from '../types'

type InputProps = FormFiledValues & {
  isLoading?: boolean
  isError?: boolean
  isSuccess?: boolean

  onChange?: (name: keyof QuickSearchForm, value: string) => void
  handleConsumptionChange?: (houseSize: string) => void
}

export const Input = ({
  texts,
  isLoading,
  isError,
  isSuccess,
  handleConsumptionChange,
  onChange,
  ...formProps
}: InputProps) => {
  const { topText, ...inputTexts } = texts
  const { colors } = useTheme()

  const {
    field: { ref, ...fieldProps },
    fieldState,
  } = useController({ ...formProps })

  const isPostalCode = fieldProps.name === 'postalCode'
  const inputRegexCheck = isPostalCode
    ? getPostalCodeRegex(browserEnvs.NEXT_PUBLIC_COUNTRY)
    : /^\d*$/

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isLoading && inputRegexCheck.test(event.target.value)) {
      onChange && onChange(fieldProps.name, event.target.value)
    }
  }

  const getIcon = () => {
    if (isLoading) {
      return <Loader />
    }
    if (isSuccess) {
      return <IconCheck color={colors.textSuccess} />
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={spacing.xxxxs} flex="1">
      {topText && <ContentText color={colors.textSecondary}>{topText}</ContentText>}
      {isPostalCode ? (
        <InputPostalCode
          {...fieldProps}
          {...inputTexts}
          inputRef={ref}
          onChange={handleChange}
          error={Boolean(fieldState.error) || isError}
          data-testid={`quick-search-form-${formProps.name}`}
          rightIcon={getIcon()}
          inputStyle="quickSearch"
        />
      ) : (
        <StyledInput
          {...fieldProps}
          {...inputTexts}
          inputRef={ref}
          onChange={handleChange}
          error={Boolean(fieldState.error) || isError}
          data-testid={`quick-search-form-${formProps.name}`}
          inputProps={{
            maxLength: formProps.name === 'housingSize' ? 3 : 5,
            inputMode: 'numeric',
          }}
        />
      )}
    </Box>
  )
}
