'use client'

import type { ChangeEvent } from 'react'

import { InputField, type InputFieldProps } from '@fortum/elemental-ui'

import { StyledInput as CheckoutStyledInput } from '@/open-web/components/Checkout/styled'
import { StyledInput as QuickSearchStyledInput } from '@/open-web/components/QuickSearch/styled'
import { browserEnvs } from '@/shared/envs'

import { formatPostalCodeValue, getPostalCodeRegex } from './utils'

type InputPostalCodeStyleOptions = 'checkout' | 'elemental' | 'quickSearch' | 'spotPrice'

export type InputPostalCodeProps = InputFieldProps & {
  countryCode?: string
  inputStyle: InputPostalCodeStyleOptions
}

export const InputPostalCode = ({
  inputStyle,
  inputRef,
  value,
  // TODO: switch to onChange: (value:string) => void, to avoid passing whole event
  onChange,
  countryCode,
  ...props
}: InputPostalCodeProps) => {
  const StyledInput = getInputStyle(inputStyle)
  const country = countryCode ?? browserEnvs.NEXT_PUBLIC_COUNTRY

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (getPostalCodeRegex(country).test(event.target.value)) {
      if (['FI', 'SE'].includes(country)) {
        event.target.value = event.target.value.replaceAll(/\D/g, '')
      }

      onChange && onChange(event)
    }
  }

  return (
    <StyledInput {...props} value={formatPostalCodeValue(country, value)} onChange={handleChange} />
  )
}

const getInputStyle = (inputStyle: InputPostalCodeStyleOptions) => {
  switch (inputStyle) {
    case 'checkout':
      return CheckoutStyledInput
    case 'quickSearch':
    case 'spotPrice':
      return QuickSearchStyledInput
    case 'elemental':
      return InputField
  }
}
