'use client'

import { usePathname } from '@/i18n/navigation'
import { routes } from '@/shared/routes'

import { QuickSearchEnterprise } from './QuickSearchEnterprise'
import { QuickSearchPrivate } from './QuickSearchPrivate'
import type { QuickSearchProps } from './types'

export const QuickSearch: React.FC<QuickSearchProps> = ({ layout = 'responsive', primaryLink }) => {
  const pathname = usePathname()
  const isEnterprise = pathname.startsWith(routes.ENTERPRISE_HOME)

  return isEnterprise ? (
    <QuickSearchEnterprise layout={layout} primaryLink={primaryLink} />
  ) : (
    <QuickSearchPrivate layout={layout} primaryLink={primaryLink} />
  )
}
