'use client'

import { BLOCKS } from '@contentful/rich-text-types'
import styled from 'styled-components'

import { Box, ButtonPill, ContentText, fontSizes, spacing, styles } from '@fortum/elemental-ui'

import { Link } from '@/i18n/navigation'
import { LinkColorProvider, RichText } from '@/shared/components/RichText'
import type { CtaEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'

import { BulletList, ListItem } from '../BulletList'
import { StyledHeading } from '../StyledHeading'

export type CtaBlockProps = Omit<CtaEntry, 'sys' | '__typename'> & {
  isInverted?: boolean | null
}

/**
 * E-com block element
 */
export const CtaBlock = ({
  topic,
  title,
  content,
  primaryLink,
  secondaryLink,
  isInverted,
}: CtaBlockProps) => {
  const { colors } = useTheme()

  const variantColors = isInverted
    ? {
        accent: colors.textLightOnDarkAccent,
        heading: colors.textHeading,
        text: colors.textLightOnDark,
        link: colors.textLightOnDark,
      }
    : {
        accent: colors.textPositive,
        heading: colors.textLarge,
        text: colors.textPrimary,
        link: colors.textLink,
      }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {topic && (
        <StyledTopicText textColor={variantColors.accent} fontSize={fontSizes.s}>
          {topic}
        </StyledTopicText>
      )}
      <StyledHeading level={3} color={variantColors.heading} pb={spacing.xxxs}>
        {title}
      </StyledHeading>
      <Box textColor={variantColors.text} flex={1}>
        <LinkColorProvider value={variantColors.link}>
          <RichText
            document={content}
            customOptions={{
              renderNode: {
                [BLOCKS.UL_LIST]: (_node, children) => (
                  <BulletList
                    colors={{
                      textPrimary: variantColors.text,
                      textPositive: variantColors.accent,
                    }}
                  >
                    {children}
                  </BulletList>
                ),
                [BLOCKS.LIST_ITEM]: (_node, children) => <ListItem>{children}</ListItem>,
              },
            }}
          />
        </LinkColorProvider>
      </Box>
      {primaryLink && (
        <Box
          display="flex"
          flexDirection={{ default: 'column', s: 'row' }}
          mt={{ default: spacing.xs, l: spacing.m }}
          gap={{ default: spacing.xs, l: spacing.xxs }}
          flexWrap={{ default: 'nowrap', l: 'wrap' }}
        >
          <ButtonPill
            tag={Link}
            href={getLinkEntryUrl(primaryLink)}
            data-testid="cta-primary-button"
            status={Boolean(isInverted) ? 'campaign' : 'primary'}
            width={{ default: '100%', l: 'fit-content' }}
          >
            {primaryLink.label}
          </ButtonPill>
          {secondaryLink && (
            <ButtonPill
              tag={Link}
              href={getLinkEntryUrl(secondaryLink)}
              data-testid="cta-secondary-button"
              status="secondary"
              invert={Boolean(isInverted)}
              transparent={Boolean(isInverted)}
              width={{ default: '100%', l: 'fit-content' }}
            >
              {secondaryLink.label}
            </ButtonPill>
          )}
        </Box>
      )}
    </Box>
  )
}

export const StyledTopicText = styled(ContentText)`
  line-height: 1.33rem;
  text-transform: uppercase;
  ${styles.breakpoint.s} {
    line-height: 1.5rem;
  }
`
