'use client'

import styled, { useTheme } from 'styled-components'

import { Box, ButtonPill, lineHeights, px2rem, spacing, styles } from '@fortum/elemental-ui'

import { Link } from '@/i18n/navigation'
import { ContentfulImage2 } from '@/shared/components/ContentfulImage2'
import { HighlightedHeading } from '@/shared/components/HighlightedHeading'
import { RichText } from '@/shared/components/RichText'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type { HeroSectionEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'

export type BasicHeroProps = Omit<HeroSectionEntry, '__typename' | 'sys'>

/**
 * Hero section.
 */
export const BasicHero: React.FC<BasicHeroProps> = (props) => {
  const { colors } = useTheme()

  const gridTemplateColumns = props.image
    ? {
        l: `5rem calc(calc(100% - 10rem) / 12 * 5) calc(calc(100% - 10rem) / 12 * 7) 5rem`,
        xxl: `1fr calc(80rem / 12 * 5) calc(80rem / 12 * 7) 1fr`,
      }
    : {
        l: `5rem 1fr 5rem`,
        xxl: `1fr 80rem 1fr`,
      }

  return (
    <Box
      display={{ default: 'flex', l: 'grid' }}
      flexDirection="column-reverse"
      gridTemplateColumns={gridTemplateColumns}
      gridTemplateRows="1fr"
      backgroundColor={colors.backgroundLightOnDark}
      position="relative"
      overflow="hidden"
    >
      <Box
        gridColumn={{ l: 2 }}
        pt={{
          default: props.image ? spacing.xxs : spacing.m,
          s: props.image ? spacing.xs : spacing.xl,
          l: spacing.xl,
        }}
        pb={{ default: spacing.m, s: spacing.xl }}
        zIndex={1}
        pl={{
          default: spacing.xxs,
          l: spacing.none,
        }}
        pr={{
          default: spacing.xxs,
          l: props.image ? '0.75rem' : spacing.none,
        }}
      >
        <Box maxWidth={{ xs: px2rem(330), s: px2rem(480), l: 'none' }} mh="auto">
          {props.title && (
            <HighlightedHeading
              lineHeight={lineHeights.compact}
              level={1}
              text={props.title}
              mb={spacing.xxs}
              textTransform="uppercase"
              fontWeight={700}
              fontSize={{
                // The font size is calculated based on the viewport width,
                // The min & max values can be found: https://design.fortum.com/elemental/#/Style/Typography.
                l: `clamp(2rem, 1em + 2cqi, 3.625rem)`,
              }}
            />
          )}
          {props.subtitle && (
            <StyledHeading
              tag="p"
              level={5}
              color={colors.textLightOnDark}
              mb={spacing.xxs}
              mt={`-${spacing.xxxs}`}
            >
              {props.subtitle}
            </StyledHeading>
          )}
          <Box textColor={colors.textLightOnDark}>
            <RichText document={props.content} />

            {(props.primaryLink || props.secondaryLink) && (
              <Box
                display="flex"
                flexDirection={{ default: 'column', s: 'row' }}
                flexWrap="wrap"
                mt={spacing.m}
                gap={{ default: spacing.xs, l: spacing.xxs }}
              >
                {props.primaryLink && (
                  <ButtonPill
                    tag={Link}
                    href={getLinkEntryUrl(props.primaryLink)}
                    data-testid="hero-primary-button"
                    status="campaign"
                  >
                    {props.primaryLink.label}
                  </ButtonPill>
                )}
                {props.secondaryLink && (
                  <ButtonPill
                    tag={Link}
                    href={getLinkEntryUrl(props.secondaryLink)}
                    data-testid="hero-secondary-button"
                    status="secondary"
                    invert
                    transparent
                  >
                    {props.secondaryLink.label}
                  </ButtonPill>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {props.image && (
        <Box
          gridColumn={{ l: '3 / 6' }}
          position="relative"
          height={{ default: px2rem(220), s: px2rem(440), l: '100%' }}
          maxHeight={{ default: px2rem(220), s: px2rem(440), l: '100%' }}
        >
          <ContentfulImage2
            priority
            fill
            image={props.image}
            boxProps={{ maxWidth: px2rem(1400), borderRadius: 0 }}
            maxWidth="1400"
          />
          <Curve />
        </Box>
      )}
    </Box>
  )
}
const Curve = styled.div`
  position: absolute;
  z-index: 0;

  ${styles.breakpointMax.l} {
    right: 0;
    bottom: 0;
    left: 0;
    height: ${px2rem(60)};
    margin-bottom: -2px;
    clip-path: polygon(0% 100%, 100% 0%, 100% 100%, 0% 100%);
    background-color: ${({ theme }) => theme.colors.backgroundLightOnDark};
  }

  ${styles.breakpointMax.s} {
    height: ${px2rem(60)};
  }

  ${styles.breakpoint.l} {
    top: 0;
    bottom: 0;
    left: 0;
    width: ${px2rem(100)};
    margin-left: -2px;
    background-color: ${({ theme }) => theme.colors.backgroundLightOnDark};
    clip-path: polygon(0% 0%, 100% 0%, 0 100%, 0% 0%);
  }
`
