export const formatPostalCodeValue = (countryCode: string, value?: string): string | undefined => {
  switch (countryCode) {
    case 'GB': //Storybook
    case 'SE':
      return value?.replace(/(\d{3})(\d{1,2})/, `$1 $2`)
    case 'FI':
    case 'NO':
    default:
      return value
  }
}

export const getPostalCodeRegex = (countryCode: string): RegExp => {
  switch (countryCode) {
    case 'SE':
      return /^[\d ]{0,6}$/
    case 'FI':
      return /^[\d]{0,5}$/
    case 'NO':
    case 'GB':
    default:
      return /^[A-Za-z0-9\- ]{0,10}$/
  }
}
