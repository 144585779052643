'use client'

import { useEffect } from 'react'

const HUBSPOT_SCRIPT_URL = 'https://js.hsforms.net/forms/v2.js'

type HubSpotFormProps = {
  hubspotFormId: string
}

const HubSpotForm = ({ hubspotFormId }: HubSpotFormProps) => {
  useEffect(
    () => {
      const existingScript = document.querySelector(`script[src="${HUBSPOT_SCRIPT_URL}"]`)
      if (!existingScript) {
        const script = document.createElement('script')
        script.src = HUBSPOT_SCRIPT_URL
        script.async = true
        script.addEventListener('load', () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              portalId: '9184050',
              formId: hubspotFormId,
              target: '#hubspotForm',
            })
          }
        })
        document.head.append(script)
      } else if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '9184050',
          formId: hubspotFormId,
          target: '#hubspotForm',
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return <div id="hubspotForm"></div>
}

export default HubSpotForm
