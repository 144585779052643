'use client'

import { useTranslations } from 'next-intl'
import React from 'react'
import styled from 'styled-components'

import type { ButtonProps } from '@fortum/elemental-ui'
import { Box, Button, px2rem, shadows, spacing } from '@fortum/elemental-ui'

import { Icon, type IconId } from '@/shared/components/Icon'
import { getElementalTheme } from '@/style/theme'

import { ANSWER_LONG_TEXT_LIMIT } from '../constants'

export const QuizAnswerButton = ({
  label,
  iconId,
  selected,
  highlighted,
  ...props
}: {
  label?: string | null
  iconId?: string | null
  selected?: boolean
  highlighted?: boolean | null
} & ButtonProps) => {
  const isLongLabel = label?.length && label.length > ANSWER_LONG_TEXT_LIMIT
  const { colors } = getElementalTheme()
  const t = useTranslations('quiz')

  const borderColor = (() => {
    if (selected) {
      return colors.borderSuccess
    } else if (highlighted) {
      return colors.brandSecondary
    }

    return colors.brandPrimary
  })()

  return (
    <Button
      {...props}
      transparent
      aria-label={label || ''}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="auto"
      borderRadius={spacing.xxxs}
      gap={spacing.xxs}
      padding={{ default: spacing.xxs }}
      minWidth={px2rem(120)}
      backgroundColor={selected ? colors.brandPrimary : colors.textLightOnDark}
      border={`2px solid ${borderColor}`}
      color={selected ? colors.textLightOnDark : colors.textLink}
      boxShadow={shadows.m}
      position="relative"
      style={{
        flexBasis: isLongLabel ? '100%' : '0',
        flexGrow: 1,
      }}
    >
      {highlighted && (
        <HighlightedTextContainer>
          <HighlightedText>{t('highlightLabel')}</HighlightedText>
        </HighlightedTextContainer>
      )}
      {iconId && (
        <Box display="flex" tag="span" justifyContent="center" alignItems="center">
          <Icon size={48} icon={iconId as IconId}></Icon>
        </Box>
      )}

      <span style={{ display: 'block', hyphens: 'auto' }}>{label}</span>
    </Button>
  )
}

const HighlightedTextContainer = styled.span`
  position: absolute;
  top: -1rem;
  left: 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
`

const HighlightedText = styled.span`
  display: block;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.poweringGreen500};
  padding: ${spacing.xxxxs} ${spacing.xxs};
  border-radius: ${spacing.xxxxs};
  color: ${({ theme }) => theme.colors.ivyGreen900};
  font-size: ${px2rem(16)};
`
