'use client'

import { useTranslations } from 'next-intl'
import React, { useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import {
  Box,
  Col,
  ContentText,
  Hidden,
  IconInfo,
  Row,
  fontSizes,
  lineHeights,
  px2rem,
  spacing,
  styles,
} from '@fortum/elemental-ui'

import { CardContainer } from '@/shared/components/CardContainer'
import type { IconId } from '@/shared/components/Icon'
import { Icon } from '@/shared/components/Icon'
import { RichText } from '@/shared/components/RichText'
import type { IvrItemEntry } from '@/shared/contentful/types'
import { rejectNil } from '@/shared/utils/array'

import { KeyLink } from '../KeyLink'
import { StyledHeading } from '../StyledHeading'

export type IvrProps = Omit<IvrItemEntry, 'sys' | '__typename'> & {
  solutionTitle?: string | null
  items?: IvrItemEntry['items']
}

export const Ivr = ({ solutionTitle, items = [] }: IvrProps) => {
  const theme = useTheme()
  const translation = useTranslations('ivrSection')

  const [selectedItem, setSelectedItem] = useState<IvrItemEntry['items'][number] | null>(
    items.length === 1 ? items[0] : null,
  )

  const filteredItems = useMemo(() => {
    return rejectNil(
      items.filter(
        (item) => item && item?.solutionsCollection?.items?.some((solution) => solution?.content),
      ),
    )
  }, [items])

  const filteredSolutions = useMemo(() => {
    return rejectNil(
      selectedItem?.solutionsCollection?.items?.filter((solution) => solution?.content) || [],
    )
  }, [selectedItem])

  const handleKeyLinkClick = (item: typeof selectedItem) => {
    setSelectedItem(item)
  }

  if (!items?.length) {
    return null
  }

  return (
    <>
      <Row
        display="flex"
        flexDirection={{ default: 'column', m: 'row' }}
        justifyContent="flex-start"
        alignItems="flex-start"
        alignSelf="stretch"
        gap={{ default: spacing.xs, m: spacing.none, l: spacing.none }}
        pb={{ default: spacing.none, m: spacing.m }}
        flexWrap="wrap"
      >
        {filteredItems.map((item, index) => {
          return (
            <React.Fragment key={item.sys.id}>
              <Col
                key={`${item.sys.id}-${index}`}
                s={12}
                m={4}
                l={2}
                display="flex"
                justifyContent="center"
                textAlign="center"
                alignSelf="stretch"
                flex="1 0 0"
                ph={{ default: spacing.none, m: px2rem(12) }}
                mt={{ m: index >= 3 ? spacing.xs : spacing.none, l: spacing.none }}
              >
                <KeyLink
                  data-testid={`key-link-${item.title}`}
                  label={item.title}
                  icon={item.icon ?? undefined}
                  isFixedHeight={false}
                  isSelected={selectedItem?.sys.id === item.sys.id}
                  onClickHandler={() => handleKeyLinkClick(item)}
                  focus={{
                    background: theme.colors.backgroundTertiary,
                    border: theme.colors.backgroundTertiary,
                  }}
                  hover={{
                    background:
                      selectedItem?.sys.id === item.sys.id
                        ? theme.colors.backgroundLightOnDark
                        : theme.colors.backgroundPrimary,
                    border:
                      selectedItem?.sys.id === item.sys.id
                        ? `${px2rem(2)} solid ${theme.colors.backgroundLightOnDark}`
                        : `${px2rem(2)} solid ${theme.colors.brandPrimary}`,
                  }}
                />
              </Col>

              {selectedItem?.sys.id === item.sys.id && (
                <Hidden above="m" width="100%">
                  <Col>
                    <StyledHeading
                      level={3}
                      marginBottom={spacing.xs}
                      textColor={theme.colors.textPrimary}
                      fontSize={fontSizes.xl}
                      lineHeight={lineHeights.normal}
                    >
                      {solutionTitle}
                    </StyledHeading>

                    <Row
                      display="flex"
                      flexDirection="column"
                      gap={spacing.xxs}
                      padding={spacing.none}
                      pt={spacing.xxs}
                    >
                      {filteredSolutions.map((solution, index) => {
                        return (
                          <Col key={`${solution.sys.id}-${index}`} m={12}>
                            <CardContainer
                              boxShadowSize={null}
                              width="100%"
                              tabIndex={0}
                              cardBadge={
                                solution.hasBadge
                                  ? {
                                      title: solution.badgeText,
                                      name: 'badge',
                                    }
                                  : undefined
                              }
                              colors={{
                                backgroundPositive: theme.colors.textLightOnDark,
                                textPositive: theme.colors.backgroundTertiary,
                              }}
                            >
                              <Box padding={spacing.xs}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  mb={spacing.xs}
                                  gap={spacing.xxs}
                                >
                                  {solution.icon && (
                                    <Icon icon={solution.icon as IconId} size={40} />
                                  )}
                                  <ContentText>{solution.title}</ContentText>
                                </Box>
                                <RichText document={solution.content} />

                                {solution.responseTime && (
                                  <Box
                                    display="flex"
                                    mt={spacing.xs}
                                    gap={spacing.xxxs}
                                    alignItems="center"
                                  >
                                    <Icon icon={IconInfo} color={theme.colors.textLink} />
                                    <ContentText fontSize={fontSizes.s}>
                                      {`${translation('responseTime')} : ${solution.responseTime}`}
                                    </ContentText>
                                  </Box>
                                )}
                              </Box>
                            </CardContainer>
                          </Col>
                        )
                      })}
                    </Row>
                  </Col>
                </Hidden>
              )}
            </React.Fragment>
          )
        })}
      </Row>

      <Hidden below="m">
        {selectedItem && (
          <>
            <StyledHeading
              level={3}
              marginBottom={spacing.xs}
              textColor={theme.colors.textPrimary}
              fontSize={fontSizes.xl}
              lineHeight={lineHeights.normal}
            >
              {solutionTitle}
            </StyledHeading>

            <StyledRow>
              {filteredSolutions.map((solution, index) => {
                return (
                  <StyledCol key={`${solution.sys.id}-${index}`}>
                    <CardContainer
                      boxShadowSize={null}
                      width="100%"
                      tabIndex={0}
                      cardBadge={
                        solution.hasBadge
                          ? {
                              title: solution.badgeText,
                              name: 'badge',
                            }
                          : undefined
                      }
                      colors={{
                        backgroundPositive: theme.colors.textLightOnDark,
                        textPositive: theme.colors.backgroundTertiary,
                      }}
                    >
                      <Box padding={spacing.xs}>
                        <Box display="flex" alignItems="center" mb={spacing.xs} gap={spacing.xxs}>
                          {solution.icon && <Icon icon={solution.icon as IconId} size={40} />}
                          <ContentText>{solution.title}</ContentText>
                        </Box>
                        <RichText document={solution.content} />
                        {solution.responseTime && (
                          <Box
                            display="flex"
                            mt={spacing.xs}
                            gap={spacing.xxxs}
                            alignItems="center"
                          >
                            <Icon icon={IconInfo} color={theme.colors.textLink} />
                            <ContentText fontSize={fontSizes.s}>
                              {`${translation('responseTime')} : ${solution.responseTime}`}
                            </ContentText>
                          </Box>
                        )}
                      </Box>
                    </CardContainer>
                  </StyledCol>
                )
              })}
            </StyledRow>
          </>
        )}
      </Hidden>
    </>
  )
}

const StyledRow = styled(Row)`
  display: flex;
  flex-flow: row wrap;

  ${styles.breakpoint.m} {
    gap: ${spacing.none};
    margin: 0 ${px2rem(-12)};
  }

  ${styles.breakpoint.l} {
    gap: ${spacing.xxxs};
    margin: 0 ${px2rem(-16)};
  }
`

const StyledCol = styled(Col)`
  display: flex;
  flex-grow: 1;

  ${styles.breakpoint.m} {
    flex-basis: calc(50% - ${px2rem(12)});
    padding: 0 ${px2rem(12)};

    &:nth-child(n + 3) {
      margin-top: ${px2rem(24)};
    }
  }

  ${styles.breakpoint.l} {
    flex-basis: calc(33.33% - ${px2rem(16)});
    padding: 0 ${px2rem(16)};

    /* Reset margin for desktop so it doesn't interfere with nth-child(n + 4) */
    &:nth-child(n + 3) {
      margin-top: 0;
    }

    &:nth-child(n + 4) {
      margin-top: ${px2rem(32)};
    }
  }
`
