'use client'

import { useState } from 'react'

import { Box, spacing } from '@fortum/elemental-ui'

import type { InPageFaqEntry } from '@/shared/contentful/types'
import { FaqPageJsonLd } from '@/shared/scripts/StructuredDataMarkup'

import { AccordionItem } from './InPageFaqAccordionItem'

type Props = {
  items: InPageFaqEntry[]
  title: string | undefined | null
}

/**
 * In-page FAQ section accordion.
 */
export const InPageFaqAccordion = ({ items, title }: Props) => {
  const [expandedItemId, setExpandedItemId] = useState<string | null>(null)

  return (
    <Box display="flex" flexDirection="column" gap={spacing.xxxs}>
      {FaqPageJsonLd(title, items)}
      {items.map((item) => (
        <AccordionItem
          key={item.sys.id}
          item={item}
          isExpanded={item.sys.id === expandedItemId}
          onToggle={() => setExpandedItemId(item.sys.id === expandedItemId ? null : item.sys.id)}
        />
      ))}
    </Box>
  )
}
