'use client'

import { useMemo } from 'react'
import { match } from 'ts-pattern'

import type { BreadcrumbItem } from '@fortum/elemental-ui'

import type { Link } from '@/i18n/navigation'
import { getPathname } from '@/i18n/navigation'
import type { EnterprisePageEntry, PageEntry } from '@/shared/contentful/types'
import { getPageRoutePath, getParentPages } from '@/shared/contentful/utils'
import { countryConfig } from '@/shared/countryConfig'
import { useTheme } from '@/shared/hooks/useTheme'
import { useLocale } from '@/shared/locale'
import { routes } from '@/shared/routes'

import { PageBreadcrumbComponent } from './PageBreadcrumbComponent'

const HOME_PAGE_SLUG = '/'

export type ColorVariant = 'default' | 'secondary'

export type PageBreadcrumbProps = {
  title?: string | null
  breadcrumb?: string | null
  slug?: string | null
  parentPage?: PageEntry['parentPage'] | EnterprisePageEntry['parentPage']
  colorVariant?: ColorVariant
  pageTypename: 'Page' | 'EnterprisePage'
}

export const PageBreadcrumb = (props: PageBreadcrumbProps) => {
  const { colors } = useTheme()
  const locale = useLocale()

  const items = useMemo(() => {
    const parents = getParentPages({ ...props, __typename: props.pageTypename }).filter(
      (page) => page.slug !== HOME_PAGE_SLUG,
    )

    const items: BreadcrumbItem<typeof Link>[] = parents.map((parent) => ({
      title: parent.breadcrumb ?? parent.title ?? '',
      params: { href: getPageRoutePath(parent), 'data-testid': 'breadcrumb-link' },
    }))

    items.push({ title: props.breadcrumb ?? props.title ?? '' })

    return items
  }, [props])

  const backgroundColor = match(props.colorVariant)
    .with('secondary', () => colors.backgroundSecondary)
    .otherwise(() => colors.backgroundPrimary)

  const homeUrl = getPathname({
    href: props.pageTypename === 'Page' ? routes.HOME : routes.ENTERPRISE_HOME,
    locale,
  })

  if (props.slug === HOME_PAGE_SLUG) {
    return null
  }

  return (
    <PageBreadcrumbComponent
      breadcrumbs={items}
      colors={{ background: backgroundColor }}
      homeUrl={`${countryConfig.basePath}${homeUrl}`}
    />
  )
}
